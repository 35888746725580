const linhkienphanloai = {
    case: require('./case.png'),
    cpu: require('./cpu.png'),
    vga: require('./vga.png'),
    drive: require('./drive.png'),
    heatsink: require('./heatsink.png'),
    main: require('./main.png'),
    ram: require('./ram.png'),
    psu: require('./psu.png'),
}

export default linhkienphanloai;