const laptopicons = {
    asus: require('./asus.png'),
    gigabyte: require('./gigabyte.png'),
    hp: require('./hp.png'),
    msi: require('./msi.png'),
    dell: require('./dell.png'),
    lenovo: require('./lenovo.png'),
    acer: require('./acer.png'),
    apple: require('./apple.png'),
}

export default laptopicons;