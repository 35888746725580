const banner2 = {
    banner1: require('~/assets/banner2/1.png'),
    banner2: require('~/assets/banner2/2.png'),
    banner3: require('~/assets/banner2/3.png'),
    banner4: require('~/assets/banner2/4.png'),
    banner5: require('~/assets/banner2/5.png'),
    banner6: require('~/assets/banner2/6.png'),
    banner7: require('~/assets/banner2/7.png'),
}

export default banner2;