const products = {
    laptop: require('~/assets/product/laptop.png'),
    mayhutbui: require('~/assets/product/mayhutbui.png'),
    phone: require('~/assets/product/phone.png'),
    phukien: require('~/assets/product/phukien.png'),
    tablet: require('~/assets/product/tablet.png'),
    TV: require('~/assets/product/TV.png'),
    washer: require('~/assets/product/washer.png'),
    smartwacth: require('~/assets/product/smartwatch.png'),
    moniter: require('~/assets/product/moniter.png'),
    linhkienmaytinh: require('~/assets/product/linhkienmaytinh.png'),
    tulanh: require('~/assets/product/tulanh.png'),
    pcdesktop: require('~/assets/product/pcdesktop.png'),
}

export default products;
