const phoneicons = {
    Benco: require('./Benco.png'),
    Honor: require('./Honor.png'),
    iphone: require('./iphone.png'),
    Oppo: require('./Oppo.png'),
    Realme: require('./Realme.png'),
    Samsung: require('./Samsung.png'),
    Vivo: require('./Vivo.png'),
    Xiaomi: require('./Xiaomi.png'),
    ZTE: require('./ZTE.png'),
    Tecno: require('./Tecno.png'),
}

export default phoneicons;