const tableticons = {
    apple: require('./apple.png'),
    coolpad: require('./coolpad.png'),
    huawei: require('./huawei.png'),
    lenovo: require('./lenovo.png'),
    honor: require('./honor.png'),
    samsung: require('./samsung.png'),
    masstel: require('./masstel.png'),
    xiaomi: require('./xiaomi.png'),
}

export default tableticons;