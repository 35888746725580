const TVbanner = {
    banner1: require("./1.png"),
    banner2: require("./2.png"),
    banner3: require("./3.png"),
    banner4: require("./4.png"),
    banner5: require("./5.png"),
    banner6: require("./6.png"),
}

export default TVbanner;
