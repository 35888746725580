const background = {
    background2: require('~/assets/background/bk2.png'),
    background3: require('~/assets/background/bk3.png'),
    background4: require('~/assets/background/bk4.png'),
    background5: require('~/assets/background/bk5.png'),
    background6: require('~/assets/background/bk6.png'),
    background7: require('~/assets/background/bk7.png'),
    background8: require('~/assets/background/bk8.png'),
    background9: require('~/assets/background/bk9.png'),
    background10: require('~/assets/background/bk10.png'),
}

export default background;