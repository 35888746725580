const laptopbanner = {
    banner1: require('~/assets/brand/laptop/laptopbanner/1.png'),
    banner2: require('~/assets/brand/laptop/laptopbanner/2.png'),
    banner3: require('~/assets/brand/laptop/laptopbanner/3.png'),
    banner4: require('~/assets/brand/laptop/laptopbanner/4.png'),
    banner5: require('~/assets/brand/laptop/laptopbanner/5.png'),
    banner6: require('~/assets/brand/laptop/laptopbanner/6.png'),
    banner7: require('~/assets/brand/laptop/laptopbanner/7.png'),
    banner8: require('~/assets/brand/laptop/laptopbanner/8.png'),
}

export default laptopbanner;
