const watchbanner = {
    banner1: require('~/assets/brand/watch/watchbanner/1.png'),
    banner2: require('~/assets/brand/watch/watchbanner/2.png'),
    banner3: require('~/assets/brand/watch/watchbanner/3.png'),
    banner4: require('~/assets/brand/watch/watchbanner/4.png'),
    banner5: require('~/assets/brand/watch/watchbanner/5.png'),
    banner6: require('~/assets/brand/watch/watchbanner/6.png'),
    banner7: require('~/assets/brand/watch/watchbanner/7.png'),
}

export default watchbanner;