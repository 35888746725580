const banner1 = {
    banner1: require('~/assets/banner1/1.png'),
    banner2: require('~/assets/banner1/2.png'),
    banner3: require('~/assets/banner1/3.png'),
    banner4: require('~/assets/banner1/4.png'),
    banner5: require('~/assets/banner1/5.png'),
    banner6: require('~/assets/banner1/6.png'),
    banner7: require('~/assets/banner1/7.png'),
    banner8: require('~/assets/banner1/8.png'),
    banner9: require('~/assets/banner1/9.png'),
    banner10: require('~/assets/banner1/10.png'),
}

export default banner1;